import React from "react"
import Seo from "../components/seo"

const cookie = () => {

return (
    <>
    <Seo title="Политика обработки файлов Cookie" 
    description="Политика обработки файлов Cookie"
    noindex={true}/>
    <section>
    <div className="container">
    <article>
        <header>
            <h1>Политика обработки файлов Cookie</h1>
        </header>
        <div>
        <p>Посещая сайт SVISNI-SUSHI.RU, принадлежащий ИП БЕЖЕНОВА ТАТЬЯНА ВИКТОРОВНА, ОГРН 318312300012678,
        далее «СВИСНИ СУШИ« в сети «Интернет», вы соглашаетесь с настоящей политикой, в том числе с тем, что 
        СВИСНИ СУШИ может использовать файлы cookie и иные данные для их последующей обработки системами 
        Google Analytics, Яндекс. Метрика и др., а также может передавать их третьим лицам для проведения исследований,
        выполнения работ или оказания услуг.</p>

        <div><b>Что такое файлы cookie</b>
        <p>Файлы cookie — текстовые файлы небольшого размера, которые сохраняются на вашем устройстве 
        (персональном компьютере, ноутбуке, планшете, мобильном телефоне и т. п.), когда вы посещаете сайты в 
        сети «Интернет».</p>

        <p>Кроме того, при посещении сайта СВИСНИ СУШИ в сети «Интернет» происходит автоматический сбор иных данных, 
        в том числе: технических характеристик устройства, IP-адреса, информации об используемом браузере и языке, 
        даты и времени доступа к сайту, адресов запрашиваемых страниц сайта и иной подобной информации.</p>
        </div>

        <div><b>Какие виды файлов cookie используются</b>
        <p>В зависимости от используемых вами браузера и устройства используются разные наборы файлов cookie, 
        включающие в себя строго необходимые, эксплуатационные, функциональные и аналитические файлы cookie.</p>
        </div>

        <div><b>Для чего могут использоваться файлы cookie</b>
        <p>При посещении вами сайта СВИСНИ СУШИ в сети «Интернет» файлы cookie могут использоваться для:</p>
        <ul>
            <li><p>обеспечения функционирования и безопасности сайта;</p></li>
            <li><p>улучшения качества сайта;</p></li>
            <li><p>регистрации в системе самообслуживания (личном кабинете);</p></li>
            <li><p>предоставлении вам информации о СВИСНИ СУШИ, его продуктах и услугах;</p></li>
            <li><p>усовершенствования продуктов и (или) услуг и для разработки новых продуктов и (или) услуг.</p></li>
            <li><p>Иная собираемая информация может быть использована для генерации вашего «списка интересов», 
            состоящего из случайного идентификатора, категории интереса и отметки времени для демонстрации вам 
            интернет-контента и рекламных объявлений, соответствующих вашим интересам.</p></li>
            <li><p>Как управлять файлами cookie</p></li>
        </ul>

        <p>Используемые вами браузер и (или) устройство могут позволять вам блокировать, удалять или иным образом 
        ограничивать использование фалов cookie. Но файлы cookie являются важной частью сайта СВИСНИ СУШИ в сети 
        «Интернет», поэтому блокировка, удаление или ограничение их использования может привести к тому, что вы 
        будете иметь доступ не ко всем функциям сайта.</p>

        <p>Чтобы узнать, как управлять файлами cookie с помощью используемых вами браузера или устройства, вы можете воспользоваться инструкцией, предоставляемой разработчиком браузера или производителем устройства.</p>
        </div>
       <p>Дата публикации: <b>10 февряля 2020 г.</b></p>

        <p>Уведомления, в случае внесения изменений, размещаются на сайте svisni-sushi.ru в виде информационного сообщения.</p>
       
        </div>
    </article>
    </div>
    </section>
    </>
    
    )
};

export default cookie
